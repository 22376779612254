import { getConfigAsync } from "../settings/config-loader";
import { KeycloakConfig } from 'keycloak-js/dist/keycloak';
import { effect, Signal } from "@angular/core";
import { KeycloakEvent, KeycloakEventType, ReadyArgs, typeEventArgs } from "keycloak-angular";
import * as AuthActions from "../auth/store/auth.actions";
import { asapScheduler } from "rxjs";
import { CoreDefaultActions } from "../store/core.actions";
import { Store } from "@ngrx/store";
import { BroadcastMessageType } from "../broadcast/broadcast.model";
import { LocalStorageKeysEnum } from "../../shared/models/enums";
import { BroadcastService } from "../broadcast/broadcast.service";
import { LocalStorageService } from "ngx-webstorage";
import Keycloak from "keycloak-js";

let settings: KeycloakConfig | null = null;

export const getSettings = (): KeycloakConfig => settings as KeycloakConfig;

export const getSettingsAsync = async (): Promise<KeycloakConfig> => {
  if (settings == null) {
    const config = await getConfigAsync();
    if(config){
      settings = config.sso;
    }
  }
  return settings as KeycloakConfig;
};


let tokenTime = 0;
const publishUpdateToken = (tokenCh: BroadcastService<number>, ls: LocalStorageService) => {
  /*
    Utilizzato per indicare alle altre tab di effettuare un controllo sul token.
    Utilizzo il metodo getTime() per avere un valore sempre diverso nella localStorage,
    in questo modo viene triggerato l'evento 'storage' nelle altre tab e posso invocare il metoto updateToken
    della libreria di keycloak.
  */
  tokenTime = new Date().getTime();
  if('BroadcastChannel' in window) {
    tokenCh.publish({ type: BroadcastMessageType.UPDATE_TOKEN, payload: tokenTime });
  } else {
    ls.store(LocalStorageKeysEnum.updateToken, tokenTime);
  }
};

export const keycloakAuthHandlerEffect = (store: Store, keycloak: Keycloak, keycloakSignal: Signal<KeycloakEvent>, tokenCh: BroadcastService<number>, ls: LocalStorageService) => effect(() => {
  const keycloakEvent = keycloakSignal();
  switch (keycloakEvent.type) {
    case KeycloakEventType.Ready: {
      // Verifica che l'utente sia autenticato
      if(typeEventArgs<ReadyArgs>(keycloakEvent.args)) {
        store.dispatch(AuthActions.login());
        /*
          Pubblico il messaggio di UPDATE_TOKEN anche dopo il login.
          In questo modo se ho più schede aperte e la sessione è scaduta, dopo il login devo "avvisare" le altre schede che la sessione non è più valida.
          Questo mostrerà il messaggio di sessione scaduta nelle altre tab nonostante mi sia appena autenticato.
          Ho previsto questo comportamento perché l'unica alternativa sarebbe quella di forzare il reload delle altre tab rischiando così che l'utente perda eventuali modifiche.
          Premesso che con un refresh token scaduto non è più possibile ottenere un token valido anche se ci siamo appena loggati in un altra scheda,
          il quesito a cui rispondere è il seguente.
          Preferisco che l'utente abbia un messaggio (apparentemente) incoerente (sessione scaduta)
          dato che mi sono appena autenticato in un altra scheda, preservando però i dati inseriti?
          Oppure preferisco che l'utente perda eventuali dati ma la pagina aggiornandosi gli permetterà eventualmente di lavorarci subito?
        */
        publishUpdateToken(tokenCh, ls);
      }
      break;
    }
    case KeycloakEventType.AuthRefreshError:
      if(keycloak.authenticated) {
        // In caso di errore dovuto ad assenza di rete retry del refresh token
        asapScheduler.schedule(() => {
          keycloak.updateToken(20);
        }, 5000);
      } else {
        store.dispatch(
          CoreDefaultActions.dispatchActionAndPublishMessageToBroadcastChannel({
            actions: [CoreDefaultActions.setSessionExpired({ sessionExpired: true })]
          })
        );
      }
      break;
    case KeycloakEventType.AuthRefreshSuccess:
      publishUpdateToken(tokenCh, ls);
      break;
  }
});