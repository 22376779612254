import { inject, Injectable } from '@angular/core';
import { createEffect, ofType } from '@ngrx/effects';
import { asapScheduler, exhaustMap, map, mergeMap, of, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import * as AuthActions from './auth.actions';
import * as RouterActions from '../../router/store/router.actions';
import * as OperatorsActions from '../../operators/store/operators.actions';
import { RoleService } from '../../role/services/role.service';
import { DocumentService } from '../../documents/services/document.service';
import { InfoCheckLicenseKey, OperatorDTOExtended } from '../../operators/operators.model';
import { LocalStorageKeysEnum } from '../../../shared/models/enums';
import { LanguageCodes } from '../../language/language.model';
import { SessionStorage, SessionStorageService } from 'ngx-webstorage';
import { Nullable } from '../../../shared/models/types';
import { Router } from '@angular/router';
import { NgRxEffectsBase } from '../../../shared/utility/NgRxUtils';
import { Action } from '@ngrx/store';
import Keycloak from 'keycloak-js';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { CustomersApiActions } from '../../customers/store/customers.action';
import { environment as Config } from "../../../../environments/environment";
import { CoreDefaultActions } from "../../store/core.actions";
import * as OperatorSelectors from "../../operators/store/operators.selector";

@Injectable({ providedIn: 'any' })
export class AuthEffects extends NgRxEffectsBase {

  private readonly router = inject(Router);
  private readonly keycloak = inject(Keycloak);
  private readonly authService = inject(AuthService);
  private readonly roleService = inject(RoleService);
  private readonly documentService = inject(DocumentService);
  private readonly sessionStorage = inject(SessionStorageService);

  @SessionStorage(LocalStorageKeysEnum.urlToOpenIfLogged)
  private readonly sessionUrlToRedirect: Nullable<string>;
  @SessionStorage(LocalStorageKeysEnum.firstLogin)
  private readonly firstLogin: Nullable<boolean>;

  public loginNew$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.login),
    exhaustMap(() => this.authService.login()
      .pipe(
        map(operator => AuthActions.loginSuccess({ operator })),
        this.rxjsUtils.advancedCatchErrorWithLog<Action>((err: HttpErrorResponse) => {
          return of(AuthActions.loginFailure({ code: err.status, reason: (err.error as HttpErrorResponse).message as InfoCheckLicenseKey }));
        },
        'Errore durante il processo di login'
        )
      )
    )
  ));

  public refreshLoggedOperator$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.refreshLoggedOperator),
    exhaustMap(() => this.authService.login()
      .pipe(
        mergeMap(operator => {
          return [
            OperatorsActions.setLoggedOperator({ loggedOperator: operator }),
            OperatorsActions.operatorsForOperator(),
            CustomersApiActions.customersForLoggedOperator()
          ];
        }),
        this.rxjsUtils.advancedCatchErrorWithLog<Action>((err: HttpErrorResponse) => {
          return of(AuthActions.loginFailure({ code: err.status, reason: (err.error as HttpErrorResponse).message as InfoCheckLicenseKey }));
        },
        'Errore nel refresh dell\'operatore loggato'
        )
      )
    )
  ));

  public loginFailure$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginFailure),
    map(({ code, reason }) => {
      let result: Action = { type: '[EMPTY]' };
      if(code === HttpStatusCode.Forbidden){
        result = RouterActions.go({ path: ['login-error'], extras: { queryParamsHandling: 'merge', queryParams: { reason } } });
      }
      return result;
    })
  ));

  public loginNewSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginSuccess),
    exhaustMap(({ operator }) => {
      // Carico prima la lingua dell'utente con transloco
      const language = (operator.operatorSettings?.value ?? 'it') as LanguageCodes;
      return this.translocoService.load(language).pipe(map(() => ({ operator, language })));
    }),
    mergeMap(({ operator, language }) => {
      const actions: Action[] = [
        OperatorsActions.setLoggedOperator({ loggedOperator: operator }),
        OperatorsActions.operatorsForOperator(),
        OperatorsActions.setActiveLanguage({ code: language })
      ];
      this.handleImages(operator);
      if (this.firstLogin != null && this.firstLogin) {
        this.sessionStorage.store(LocalStorageKeysEnum.firstLogin, false);
        asapScheduler.schedule(() => {
          this.router.navigateByUrl(this.buildOperatorHomeRoute(operator));
        });
      }
      return actions;
    })
  ));

  public logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    tap(({ homePage }) => {
      this.store.dispatch(CoreDefaultActions.toggleMainLoader({ show: true }));
      this.store.dispatch(OperatorsActions.clearState());
      this.store.dispatch(AuthActions.clearState());

      let redirectUri = `${location.origin}${Config.baseHref}`;

      // Se true faccio il redirect alla home page dell'utente
      if(homePage) {
        this.sessionStorage.store(LocalStorageKeysEnum.firstLogin, true);
        this.sessionStorage.store(LocalStorageKeysEnum.urlToOpenIfLogged, null);

        const operator = this.store.selectSignal(OperatorSelectors.loggedOperator)();
        const userHomePage = operator.operatorSettings?.otherSettings?.HOME_PAGE_FWO_FUNCTIONALITY;
        if (userHomePage) {
          redirectUri = location.origin + `${Config.baseHref}/${this.roleService.getWebOperationPathById(userHomePage, operator)}`.replace('//', '/');
        } else {
          redirectUri = location.origin + `${Config.baseHref}/my-profile`.replace('//', '/');
        }
      }

      this.keycloak.logout({ redirectUri });
    })
  ), { dispatch: false });

  // Metodo che gestisce le immagini statiche nella localstorage
  private handleImages (operator: OperatorDTOExtended): void {
    if (operator.photoLink) {
      this.documentService.handleOperatorProfileImg(operator.photoLink);
    } else {
      this.documentService.updateDbProfileImg('', new Blob(), true);
    }
    if (operator.signature) {
      this.documentService.handleOperatorSignature(operator.signature);
    } else {
      this.documentService.updateDBOperatorSignature('', new Blob(), true);
    }
  }

  private buildOperatorHomeRoute (operator: OperatorDTOExtended): string {
    // Se l'utente ha tentato di raggiungere un modulo senza essere loggato eseguo il redirect dopo il login
    let route = '/my-profile';
    if (this.sessionUrlToRedirect && this.sessionUrlToRedirect.trim().length > 0) {
      route = this.sessionUrlToRedirect;
    } else {
      // Recupero la home page nel caso non ci sia un'altra pagina da aprire
      const homePage = operator.operatorSettings?.otherSettings?.HOME_PAGE_FWO_FUNCTIONALITY;
      if (homePage) {
        route = `/${this.roleService.getWebOperationPathById(homePage, operator)}`;
      }
    }
    return route;
  }
}
