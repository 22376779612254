import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/core/app.config';
import { AppComponent } from './app/app.component';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import { registerLicense } from '@syncfusion/ej2-base';
import { environment as Config } from './environments/environment';
import { enableMapSet } from 'immer';
import {
  CellStyleModule,
  ClientSideRowModelModule,
  ColumnAutoSizeModule,
  ColumnHoverModule,
  CustomFilterModule,
  DateFilterModule,
  LocaleModule,
  ModuleRegistry,
  NumberFilterModule,
  RowSelectionModule,
  TextFilterModule,
  ValidationModule,
  ValueCacheModule,
} from 'ag-grid-community';

dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(utc);

// Import moduli AgGrid
ModuleRegistry.registerModules([
  ValidationModule,
  ColumnAutoSizeModule,
  ColumnHoverModule,
  CellStyleModule,
  TextFilterModule,
  NumberFilterModule,
  DateFilterModule,
  CustomFilterModule,
  RowSelectionModule,
  LocaleModule,
  ValueCacheModule,
  ClientSideRowModelModule
]);

// Abilito la gestione delle mappe e dei set in immer
enableMapSet();

// Registering Syncfusion license key
registerLicense(Config.sfKey);

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
