import { Action, createActionGroup, emptyProps, props } from '@ngrx/store';
import { CustomerDTO } from '../customerDTO';
import { NgRxUtils } from '../../../shared/utility/NgRxUtils';
import {
  DatiPersonalizzatiTypes,
  DatoPersonalizzato,
  TdpPropagationDTO
} from "../../dato-personalizzato/dato-personalizzato.model";
import { Nullable } from "../../../shared/models/types";

export const CustomersApiActions = createActionGroup({
  source: 'Customers/API',
  events: {
    'all customers': emptyProps(),
    'all customers success': props<{ customerList: CustomerDTO[] }>(),
    'all customers failure': NgRxUtils.httpStatusProps(),

    'customers for logged operator': emptyProps(),
    'customers for logged operator success': props<{ customerListXLoggedOpe: CustomerDTO[] }>(),
    'customers for logged operator failure': NgRxUtils.httpStatusProps(),

    'get by id': props<{ id: number }>(),
    'get by id success': props<{ customer: CustomerDTO }>(),
    'get by id failure': NgRxUtils.httpStatusProps(),

    'create customer': props<{ customer: Partial<CustomerDTO>, redirectToDetail?: boolean, actions?: Action[] }>(),
    'create customer success': props<{ customer: CustomerDTO, addToVisibleCustomers: boolean }>(),
    'create customer failure': NgRxUtils.httpStatusProps(),

    'update customer': props<{ customer: CustomerDTO, actions?: Action[] }>(),
    'update customer success': props<{ customer: CustomerDTO }>(),
    'update customer failure': NgRxUtils.httpStatusProps(),

    'delete customer': props<{ id: number }>(),
    'delete customer success': props<{ id: number }>(),
    'delete customer failure': NgRxUtils.httpStatusProps(),

    'upload logo': props<{ formData: FormData, base64: string }>(),
    'upload logo success': props<{ base64: string }>(),
    'upload logo failure': NgRxUtils.httpStatusProps(),

    'propagate tdp': props<{ body: TdpPropagationDTO }>(),
    'propagate tdp success': props<{ res: Nullable<DatoPersonalizzato[]> }>(),
    'propagate tdp failure': NgRxUtils.httpStatusProps()
  }
});

export const CustomersDefaultActions = createActionGroup({
  source: 'Customers',
  events: {

    'set show modal link': props<{ showModalLink: boolean }>(),
    'clear errors': emptyProps(),
    'clear state': emptyProps()
  }
});
